/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import './Card.less';

const StickCard = ({ children, className = '', style = {}, ...props }) => {
    return (
        <div
            className={`card-wrapper ${className}`}
            style={style}
            {...props}>
            {children}
        </div>
    );
};

StickCard.Title = function CardTitle({ children, ...props }) {
    return <h3 {...props}>{children}</h3>;
};

StickCard.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    style: PropTypes.object
};

export default StickCard;
