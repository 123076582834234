export const isSupported = () => {
    try {
        const testKey = '__stick_secure_storage_test_key__';
        setItem(testKey, testKey);
        removeItem(testKey);
        return true;
    } catch (error) {
        if (error instanceof Error && error.name === 'SecurityError') {
            // [localStorage] Unable to remove item due to security settings
        }
        return false;
    }
};

export function getItem(key) {
    try {
        return window.localStorage.getItem(key);
    } catch (error) {
        if (error instanceof Error && error.name === 'SecurityError') {
            // [localStorage] Unable to get item due to security settings
        }

        return;
    }
}

export function setItem(key, value) {
    try {
        return window.localStorage.setItem(key, value);
    } catch (error) {
        if (error instanceof Error && error.name === 'SecurityError') {
            // [localStorage] Unable to set item due to security settings
        }

        return;
    }
}

export function removeItem(key) {
    try {
        return window.localStorage.removeItem(key);
    } catch (error) {
        if (error instanceof Error && error.name === 'SecurityError') {
            // [localStorage] Unable to remove item due to security settings
        }

        return;
    }
}
