import React from 'react';
import { FormSpy } from 'react-final-form';
import isEqual from 'lodash.isequal';
import diff from 'object-diff';

class AutoSave extends React.Component {
    static defaultProps = {
        debounced: []
    };
    constructor(properties) {
        super(properties);
        this.state = {
            values: properties.values,
            dirty: properties.dirty,
            pristine: properties.pristine,
            submitting: false,
            isTest: properties?.isTest || false
        };
    }

    componentDidUpdate(previousProperties) {
        const { values, dirty, pristine, debounce, debounced } = this.props;
        if (dirty && !pristine && !isEqual(previousProperties.values, values)) {
            const debouncedValues = {};
            const immediateValues = {};
            for (const key of Object.keys(values)) {
                if (debounced.includes(key)) {
                    debouncedValues[key] = values[key];
                } else {
                    immediateValues[key] = values[key];
                }
            }
            immediateValues['notes']?.length >= 1 &&
                immediateValues['notes'][0]?.note?.length &&
                delete immediateValues['notes'];
            if (Object.keys(immediateValues).length > 0) {
                this.save(immediateValues);
            }
            debouncedValues['notes']?.length >= 1 &&
                debouncedValues['notes'][0]?.note?.length &&
                delete debouncedValues['notes'];
            if (Object.keys(debouncedValues).length > 0) {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                }
                this.timeout = setTimeout(() => {
                    this.save(debouncedValues);
                }, debounce);
            }
        }
    }

    save = async (values) => {
        if (this.promise) {
            await this.promise;
        }
        const { save } = this.props;

        const changedValues = {};
        for (const key of Object.keys(values)) {
            if (!isEqual(values[key], this.state.values[key])) {
                changedValues[key] = values[key];
            }
        }
        if (Object.keys(changedValues).length > 0) {
            diff(this.state.values);
            // values have changed
            this.setState((state) => ({
                submitting: true,
                values: { ...state.values, ...changedValues }
            }));
            this.promise = save(changedValues);
            await this.promise;
            delete this.promise;
            this.setState({ submitting: false });
        }
    };
    render() {
        const { values, isTest } = this.state;
        return (
            values &&
            isTest && (
                <div>
                    values:
                    <div>{JSON.stringify(values, null, 1)}</div>
                </div>
            )
        );
    }
}

// Make a HOC
// This is not the only way to accomplish auto-save, but it does let us:
// - Use built-in React lifecycle methods to listen for changes
// - Maintain state of when we are submitting
// - Render a message when submitting
// - Pass in debounce and save props nicely
export default (properties) => (
    <FormSpy
        {...properties}
        subscription={{ values: true, dirty: true, pristine: true }}
        component={AutoSave}
    />
);
