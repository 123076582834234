import { createSlice } from '@reduxjs/toolkit';
import { getItem } from '../utilities/storage';

const initialState = {
    userId: null,
    organizations: [],
    selectedOrganization: null,
    setGuidePassedValue: null,
    role: null,
    locale: getItem('locale') ?? 'en-AU',
    permissions: []
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserState(state, action) {
            Object.assign(state, action.payload);
        }
    }
});

export const { setUserState } = userSlice.actions;

export default userSlice.reducer;
