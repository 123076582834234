import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useAxios from '../hooks/useAxios';
import config from '../config';

export const useValidateOrganization = (organizationId) => {
    const api = useAxios(config.baseApiUrl);
    return useQuery({
        queryKey: ['organization', organizationId],
        queryFn: async () => {
            const response = await api.get(`tenants/${organizationId}/validate`);

            return {
                statusCode: response.status,
                data: response.data?.isActive
            };
        },
        enabled: !!organizationId,
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            if (error instanceof AxiosError) {
                return ![400, 401, 403, 404, 422].includes(error.response?.status || 0) && failureCount < 3;
            }
            return failureCount < 3;
        }
    });
};

export const useFetchUsersOrganizations = (shouldFetch, userId) => {
    const api = useAxios(config.baseApiUrl);
    return useQuery({
        queryKey: ['organizations', shouldFetch, userId],
        queryFn: () => api.get(`users/${userId}/tenants`).then((response) => response.data),
        enabled: shouldFetch && !!userId,
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            if (error instanceof AxiosError) {
                return ![400, 401, 403, 404, 422].includes(error.response?.status || 0) && failureCount < 3;
            }
            return failureCount < 3;
        }
    });
};
