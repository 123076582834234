import { Component, Fragment } from 'react';
import { Result } from 'antd';
import * as Sentry from '@sentry/react';
import config from '../../../config';
import '../../../utilities/sentry';
import StickButton from '../Button/Button';

class SentryBoundary extends Component {
    constructor(properties) {
        super(properties);
        this.state = {
            error: undefined,
            eventId: undefined
        };
    }

    static getDerivedStateFromError(error) {
        if (['staging', 'production'].includes(config.mode)) {
            this.setState({ error });
            const eventId = Sentry.captureException(error);
            this.setState({ eventId });
        }
    }

    componentDidCatch(error, errorInfo) {
        if (['staging', 'production'].includes(config.mode)) {
            this.setState({ error });

            Sentry.withScope((scope) => {
                scope.setExtras(errorInfo);
                const eventId = Sentry.captureException(error);
                this.setState({ eventId });
            });
        }
    }

    handleRefresh() {
        window.location.reload();
    }

    render() {
        const { children } = this.props;
        const { error, eventId } = this.state;

        return error ? (
            <Result
                title='Oops, something went wrong.'
                subTitle={
                    <Fragment>
                        <p>Sorry, something went wrong.</p>
                        <p>
                            Unfortunately, your request could not be processed. Please try again, or refresh the page.
                        </p>
                        <p>
                            If you keep seeing this error, then let us know and we’ll try to find out what might be
                            happening in your case.
                        </p>
                    </Fragment>
                }
                extra={[
                    <StickButton
                        type='primary'
                        key='reload'
                        onClick={this.handleRefresh.bind(this)}>
                        Reload Page
                    </StickButton>,
                    <StickButton
                        type='secondary'
                        key='support'
                        onClick={() => Sentry.showReportDialog({ eventId })}>
                        Contact Support
                    </StickButton>
                ]}
            />
        ) : (
            children
        );
    }
}

export default SentryBoundary;
