import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const AuthProviderComponent = ({ children }) => {
    const navigate = useNavigate();

    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    const options = {
        audience: import.meta.env.VITE_API_BASE_URL,
        scope: 'openid profile email roles read:organisations update:organisations',
        redirect_uri: window.location.origin
    };

    const parameters = new URL(document.location.href).searchParams;
    if (parameters.has('invitation')) {
        options.invitation = parameters.get('invitation');
    }
    if (parameters.has('organization')) {
        options.organization = parameters.get('organization');
    }
    if (!options.organization && !options.invitation && localStorage.getItem('org_id')) {
        options.organization = localStorage.getItem('org_id');
    }
    return (
        <Auth0Provider
            domain={import.meta.env.VITE_AUTH0_DOMAIN}
            clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
            authorizationParams={options}
            cacheLocation='localstorage'
            onRedirectCallback={onRedirectCallback}>
            {children}
        </Auth0Provider>
    );
};

export default AuthProviderComponent;
