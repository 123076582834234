import { createSlice } from '@reduxjs/toolkit';
import { commonInitialState, commonReducers } from './commonSlice';

const createCommonSlice = (name, initialState = {}, extraReducers = {}) => {
    return createSlice({
        name,
        initialState: {
            ...commonInitialState,
            ...initialState
        },
        reducers: {
            ...commonReducers,
            ...extraReducers
        }
    });
};

export default createCommonSlice;
