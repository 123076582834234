import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

export const localesConfig = {
    'en-AU': {
        antdPath: 'en_GB'
    }
};

const newInstance = i18n.createInstance();

newInstance
    .use(Backend)
    .use(detector)
    .use(initReactI18next)
    .init({
        supportedLngs: ['en-AU'],
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        ns: ['common'],
        defaultNS: 'common',
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        debug: false,
        fallbackLng: ['en-AU']
    });

export default newInstance;
