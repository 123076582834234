import { useMemo } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

const useAxios = (baseURL, defaultConfig = {}) => {
    const { getAccessTokenSilently, logout } = useAuth0();
    const { locale } = useSelector((state) => state.user);

    const http = useMemo(() => {
        const instance = axios.create({
            baseURL: baseURL || '/',
            headers: {
                'content-type': 'application/json',
                ...defaultConfig.headers
            },
            ...defaultConfig
        });

        instance.interceptors.request.use(
            async (config) => {
                const token = await getAccessTokenSilently();

                if (locale) {
                    config.headers['Accept-Language'] = locale;
                }

                if (token) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }

                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        instance.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                switch (error.statusCode) {
                    case 401: {
                        await logout({ returnTo: window.location.origin });

                        break;
                    }
                    //case 403: {
                    //    navigate('/unauthorized');

                    //    break;
                    //}
                    //case 404: {
                    //    navigate('/notfound');

                    //    break;
                    //}
                    //case 429: {
                    //    navigate('/toomanyrequests');

                    //    break;
                    //}
                    default: {
                        if (axios.isCancel(error)) {
                            return;
                        }

                        //TODO: Display error message
                        //dispatch(displayError(error));

                        break;
                    }
                }

                throw error;
            }
        );

        return instance;
    }, [baseURL, defaultConfig, getAccessTokenSilently, locale]);

    return useMemo(
        () => ({
            http,

            get(resource, parameters) {
                return http.get(`api/${resource}`, parameters);
            },

            post(resource, parameters, config) {
                return http.post(`api/${resource}`, parameters, config);
            },

            update(resource, slug, parameters) {
                return http.put(`api/${resource}/${slug}`, parameters);
            },

            put(resource, parameters) {
                return http.put(`api/${resource}`, parameters);
            },

            patch(resource, parameters, config) {
                return http.patch(`api/${resource}`, parameters, config);
            },

            delete(resource, parameters) {
                return http.delete(`api/${resource}`, parameters);
            }
        }),
        [http]
    );
};

export default useAxios;
