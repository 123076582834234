import memoizeOne from 'memoize-one';
import UAParser from 'ua-parser-js';

const parser = new UAParser();

export default parser;

export const parseUserAgent = memoizeOne((userAgent) => {
    const ua = userAgent || navigator.userAgent;
    return parser.setUA(ua).getResult();
});

export const DeviceType = {
    CONSOLE: 'console',
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
    TABLET: 'tablet',
    SMARTTV: 'smarttv',
    WEARABLE: 'wearable',
    EMBEDDED: 'embedded'
};

export function getDevice(userAgent) {
    const ua = userAgent || navigator.userAgent;
    const { type } = parseUserAgent(ua).device;

    return type;
}
