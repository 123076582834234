/* these are the selectors to get the states of the values inside the slice,
    using these we can create other slices */

import {
    controlAdapter,
    controlCategoryAdapter,
    controlMaturityStatusAdapter,
    controlResponseAdapter,
    controlSetAdapter
} from '../Adapters';

const selectAssessmentState = (state, slice) => {
    return state[slice].currentAssessment;
};

const { selectAll: selectAllControlMaturityStatuses } = controlMaturityStatusAdapter.getSelectors(
    (state, slice) => state[slice].controlMaturityStatuses
);

const selectControlMaturityStatusById = (state, slice, id) => {
    const selectors = controlMaturityStatusAdapter.getSelectors((state, slice) => state[slice].controlMaturityStatuses);
    return selectors.selectById(state, id);
};

const { selectAll: selectAllControlCategories } = controlCategoryAdapter.getSelectors(
    (state, slice) => state[slice].controlCategories
);

const selectControlCategoryById = (state, slice, id) => {
    const selectors = controlCategoryAdapter.getSelectors((state, slice) => state[slice].controlCategories);
    return selectors.selectById(state, id);
};

const { selectAll: selectAllControlSets } = controlSetAdapter.getSelectors((state, slice) => state[slice].controlSets);

const selectControlSetById = (state, slice, id) => {
    const selectors = controlSetAdapter.getSelectors((state, slice) => state[slice].controlSets);
    return selectors.selectById(state, id);
};

const { selectAll: selectAllControls } = controlAdapter.getSelectors((state, slice) => state[slice].controls);

const selectControlById = (state, slice, id) => {
    const selectors = controlAdapter.getSelectors((state) => state[slice].controls);
    return selectors.selectById(state, id);
};

const { selectAll: selectAllControlResponses } = controlResponseAdapter.getSelectors(
    (state, slice) => state[slice].controlResponses
);

const selectResponseByControlId = (state, slice, id) => {
    const selectors = controlResponseAdapter.getSelectors((state) => state[slice].controlResponses);
    return selectors.selectById(state, id);
};

const selectActiveControlState = (state, slice) => state[slice].activeControl;
const selectSelectedMultiControls = (state, slice) => state[slice].selectedMultipleControls;

export {
    selectAllControlCategories,
    selectAllControlMaturityStatuses,
    selectAllControlSets,
    selectAllControls,
    selectControlById,
    selectControlCategoryById,
    selectControlMaturityStatusById,
    selectControlSetById,
    selectResponseByControlId,
    selectAssessmentState,
    selectAllControlResponses,
    selectActiveControlState,
    selectSelectedMultiControls
};
