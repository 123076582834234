import { pathToRegexp } from 'path-to-regexp';

export const normalizePathname = (pathname) => {
    return pathname.split('?')[0].split('#')[0];
};

export const findCurrentRoute = (routes, pathname, parameters = {}) => {
    const normalizedPathname = normalizePathname(pathname);
    for (const route of routes) {
        try {
            const keys = [];
            const regexp = pathToRegexp(route.path, keys);
            const pathMatch = regexp.exec(normalizedPathname);

            if (pathMatch) {
                // Extract dynamic route parameters
                for (const [index, key] of keys.entries()) {
                    parameters[key.name] = pathMatch[index + 1];
                }

                return route;
            }

            if (route.children) {
                const nestedRoute = findCurrentRoute(route.children, pathname, parameters);
                if (nestedRoute) {
                    return nestedRoute;
                }
            }
        } catch {
            // intentionally ignored
            continue;
        }
    }

    return null;
};

export const findParentRoute = (childRoute, routeList) => {
    for (const route of routeList) {
        if (route.children?.includes(childRoute)) {
            return route;
        } else if (route.children) {
            const parent = findParentRoute(childRoute, route.children);
            if (parent) {
                return parent;
            }
        }
    }
    return null;
};
