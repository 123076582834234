const ENVIRONMENT = import.meta.env;

export default {
    mode: ENVIRONMENT.MODE,
    release: `${ENVIRONMENT.VITE_APP_NAME}@${ENVIRONMENT.VITE_APP_VERSION}`,
    version: ENVIRONMENT.VITE_APP_VERSION,
    latestCommitHash: ENVIRONMENT.VITE_APP_LATEST_COMMIT_HASH,
    title: ENVIRONMENT.VITE_META_TITLE,
    // baseApiUrl: ENVIRONMENT.MODE === 'production' ? ENVIRONMENT.VITE_API_BASE_URL : '',
    baseApiUrl: ENVIRONMENT.VITE_API_BASE_URL,
    maintenanceMode: ENVIRONMENT.VITE_APP_MAINTENANCE_MODE,
    domain: ENVIRONMENT.VITE_AUTH0_DOMAIN,
    clientId: ENVIRONMENT.VITE_AUTH0_CLIENT_ID,
    authorizationParams: {
        audience: ENVIRONMENT.VITE_AUTH0_API_AUDIENCE,
        redirectUri: ENVIRONMENT.VITE_AUTH0_REDIRECT_URI
    }
};
