import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { init, reactRouterV6BrowserTracingIntegration, replayIntegration } from '@sentry/react';
import config from '../config';

if (['production', 'staging'].includes(config.mode)) {
    init({
        dsn: import.meta.env.VITE_APP_SENTRY_DSN,
        release: config.release, //Sourcemaps are tethered to release
        integrations: [
            // See docs for support of different versions of variation of react router
            // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
            reactRouterV6BrowserTracingIntegration(
                createRoutesFromChildren,
                matchRoutes,
                useEffect,
                useLocation,
                useNavigationType
            ),
            replayIntegration()
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 0.1,
        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1,
        // Ignore errors
        ignoreErrors: [
            //General Errors
            'ChunkLoadError',
            'Loading CSS chunk',
            'Timeout (n)',
            'Uncaught (in promise) Timeout (n)',
            'Non-Error exception captured',
            'Non-Error promise rejection captured',
            /^Object captured as promise rejection with keys:.+$/,
            //Auth0 error
            'Login required',
            'Multifactor authentication required',
            //React doc view error
            'Failed to fetch'
        ]
    });
}
