import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import PropTypes from 'prop-types';
import { RouteIconComponent } from '../Common';
import { findCurrentRoute, findParentRoute } from '../../utilities/route';
import './Breadcrumbs.less';

const BreadcrumbComponent = ({ routeList, location }) => {
    const parameters = {};
    // Find a route that matches the pathname.
    const currentRoute = findCurrentRoute(routeList, location.pathname, parameters);

    const paths = [];

    const buildBreadcrumbs = (route) => {
        if (route) {
            const title = (
                <Fragment>
                    {route.icon && (
                        <span style={{ marginRight: 4 }}>
                            <RouteIconComponent type={route.icon} />
                        </span>
                    )}
                    {route.path == currentRoute.path ? (
                        <a>{route.label}</a>
                    ) : (
                        <Link to={route.path}>{route.label}</Link>
                    )}
                </Fragment>
            );

            paths.unshift({
                title: title,
                key: route.path
            });

            const parentRoute = findParentRoute(route, routeList);

            if (parentRoute) {
                buildBreadcrumbs(parentRoute);
            }
        }
    };

    buildBreadcrumbs(currentRoute);

    // Add the home route (dashboard) to the breadcrumbs.
    if (currentRoute && routeList.length > 0 && currentRoute.path !== routeList[0]?.path) {
        buildBreadcrumbs(routeList[0]);
    }

    return (
        <Breadcrumb
            className='breadcrumb'
            items={paths}
        />
    );
};

BreadcrumbComponent.propTypes = {
    routeList: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired
};

BreadcrumbComponent.defaults = {
    routeList: [],
    location: {}
};

export default BreadcrumbComponent;
