/* eslint-disable no-unused-vars */
import '@fontsource-variable/montserrat';
import { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Result, Typography } from 'antd';
import { jwtDecode } from 'jwt-decode';
import { setUserState } from '../../store/userReducer';
import { useValidateOrganization } from '../../api/auth0';
import { LoaderComponent } from '../Common';
import { useNavigate } from 'react-router-dom';
import Maintenance from '../../pages/Maintenance';
import RenderRouter from '../../routes';
import config from '../../config';
import '../../styles/index.less';
import StickButton from '../Common/Button/Button';
import { Permission } from '@/components/enum/Permission';
const isMaintenanceMode = () => (config.maintenanceMode || false) === 'true';

const { Paragraph, Title } = Typography;

const getPassedGuideOrganizationList = (decodedToken, appMetadataKey) =>
    Object.prototype.hasOwnProperty.call(decodedToken, appMetadataKey) &&
    Object.prototype.hasOwnProperty.call(decodedToken[appMetadataKey], 'PassedGuideOrganizationList')
        ? decodedToken[appMetadataKey].PassedGuideOrganizationList
        : null;

const App = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        isLoading: authenticationIsLoading,
        isAuthenticated,
        getAccessTokenSilently,
        getTokenWithPopup,
        loginWithRedirect,
        authenticationError,
        user,
        logout
    } = useAuth0();
    const { selectedOrganization, setGuidePassedValue } = useSelector((state) => state.user);
    const {
        data: validationResult,
        error: validationError,
        isLoading: validationIsLoading,
        refetch
    } = useValidateOrganization(selectedOrganization);
    const [permissions, setPermissions] = useState([]);
    const [userValidated, setUserValidated] = useState(false);

    useEffect(() => {
        if (authenticationIsLoading) {
            return;
        }
        if (isAuthenticated) {
            const setOrganization = async () => {
                let token;

                try {
                    token = await getAccessTokenSilently();
                } catch (error) {
                    if (
                        ['login_required', 'missing_refresh_token', 'invalid_grant'].includes(error.error) ||
                        error.message === 'External interaction required'
                    ) {
                        await logout({
                            onRedirect: async () => {}
                        });

                        return;
                    } else {
                        throw error;
                    }
                }

                if (!token) {
                    await loginWithRedirect({
                        appState: { targetUrl: window.location.href }
                    });

                    return;
                }
                const decodedToken = jwtDecode(token);

                const appMetadataKey = `${import.meta.env.VITE_API_BASE_URL}/app_metadata`;
                const role = decodedToken[`${import.meta.env.VITE_API_BASE_URL}/roles`][0];
                const passedGuideOrganizationList = getPassedGuideOrganizationList(decodedToken, appMetadataKey) ?? [];
                dispatch(
                    setUserState({
                        userId: decodedToken.sub,
                        selectedOrganization: decodedToken.org_id,
                        roles: role,
                        permissions: decodedToken.permissions
                    })
                );

                setPermissions(decodedToken.permissions);
                setUserValidated(true);
                localStorage.setItem('org_id', decodedToken.org_id);

                let organizationList = localStorage.getItem('passedGuideOrganizationList') ?? [];
                if (organizationList.length === 0) {
                    localStorage.setItem('passedGuideOrganizationList', JSON.stringify(passedGuideOrganizationList));
                    organizationList = passedGuideOrganizationList;
                }
                const hasPermission = decodedToken.permissions.includes(Permission.onboardingPageView);
                !organizationList.includes(decodedToken.org_id) && hasPermission && navigate('/onboardingPage');
            };
            setOrganization();
        } else {
            const login = async () =>
                await loginWithRedirect({
                    appState: { targetUrl: window.location.href }
                });

            login();
        }
    }, [authenticationIsLoading, isAuthenticated, getAccessTokenSilently, getTokenWithPopup, setGuidePassedValue]);

    useEffect(() => {
        if (validationIsLoading) {
            return;
        }

        setUserValidated(true);
    }, [validationIsLoading, validationResult]);

    if (authenticationError || validationError) {
        let subTitle =
            'An error occurred while authenticating. Please try again. Please contact support if the problem persists.';
        if (config.mode == 'development') {
            subTitle += ` Error: ${authenticationError?.message || validationError?.message}`;
        }
        const retryAction = authenticationError ? loginWithRedirect : refetch;
        return (
            <Result
                status='error'
                title='Authentication Error'
                subTitle={subTitle}
                extra={
                    <StickButton
                        type='primary'
                        onClick={retryAction}>
                        Retry Login
                    </StickButton>
                }
            />
        );
    }

    if (validationResult?.StatusCode > 201) {
        return (
            <Result
                status='error'
                title='No Subscriptions Found'
                subTitle={
                    <>
                        <Title level={5}>Before you can start using StickSecure, you need to get a subscription.</Title>
                        <Paragraph>
                            We were unable to find any StickSecure subscriptions associated with your account. To access
                            StickSecure's features and services, you'll need to purchase a subscription through our
                            website.
                        </Paragraph>
                        <Paragraph>
                            You are currently signed in as {user.email} in the account {selectedOrganization}. If this
                            was not the account you intended to use, please sign out and sign in again using the
                            intended account.
                        </Paragraph>
                    </>
                }
                extra={
                    <StickButton
                        type='primary'
                        onClick={logout}>
                        Logout
                    </StickButton>
                }
            />
        );
    }

    if (isMaintenanceMode()) {
        return <Maintenance />;
    }

    if (authenticationIsLoading || validationIsLoading || !userValidated) {
        return (
            <LoaderComponent
                fullScreen={true}
                overlay={true}
            />
        );
    }

    return (
        <Suspense
            fallback={
                <LoaderComponent
                    fullScreen={true}
                    overlay={true}
                />
            }>
            <RenderRouter permissions={permissions} />
        </Suspense>
    );
};

export default App;
