//create selectors dynamically for slices like internal, assessment
export const createSelectorsForSlice = (baseSelectors, sliceName) => {
    return Object.keys(baseSelectors).reduce((acc, selectorName) => {
        const selector = baseSelectors[selectorName];
        acc[selectorName] = (state, ...args) => selector(state, sliceName, ...args);
        return acc;
    }, {});
};

export const createDerivedSelectorsForSlice = (derivedSelectors, sliceName) => {
    const selectorsWithSliceName = {};

    Object.keys(derivedSelectors).forEach((selectorName) => {
        const selectorHOF = derivedSelectors[selectorName];
        selectorsWithSliceName[selectorName] = selectorHOF(sliceName);
    });

    return selectorsWithSliceName;
};
