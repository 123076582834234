export const AssessmentProgressStatus = Object.freeze({
    InProgress: { id: 0, value: 'In Progress', color: '#3498DB' },
    Completed: { id: 1, value: 'Completed', color: '#70BA50' },
    Preparing: { id: 3, value: 'Preparing', color: '#FF7416' }
    // OnHold: { id: 2, value: 'On Hold', color: '#FF7416' }
});

export const renderAssessmentProgressStatus = (assessmentProgressStatusId, assessmentProgressStatusName) => {
    const status = assessmentProgressStatusId
        ? Object.values(AssessmentProgressStatus).find(
              (status) =>
                  status.id === assessmentProgressStatusId ||
                  (assessmentProgressStatusName && status.value === assessmentProgressStatusName)
          )
        : AssessmentProgressStatus.InProgress;
    return (
        <span
            style={{
                backgroundColor: status?.color || AssessmentProgressStatus.InProgress.color,
                borderRadius: '4px',
                width: '111px',
                height: '24px',
                display: 'inline-block',
                textAlign: 'center',
                lineHeight: '24px'
            }}>
            {/*   <span
                style={{
                    backgroundColor: status?.color || AssessmentProgressStatus.InProgress.color,
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginRight: '4px'
                }}></span> */}
            {status.value}
        </span>
    );
};
