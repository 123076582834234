import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const useCheckPermissions = (permissionNames) => {
    const [loading, setLoading] = useState(true);
    const [hasPermission, setHasPermission] = useState(null);
    const { permissions } = useSelector((state) => state.user, shallowEqual);

    useEffect(() => {
        setLoading(true);
        let permissionsToCheck = Array.isArray(permissionNames) ? permissionNames : [permissionNames];
        if (!permissionsToCheck || permissionsToCheck.length === 0) {
            setLoading(false);
            return;
        }
        const userPermissions = permissions || [];
        const hasAnyPermission = permissionsToCheck.every((permission) => userPermissions.includes(permission));
        setHasPermission(hasAnyPermission);
        setLoading(false);
    }, [permissions]);

    return {
        hasPermission,
        loading
    };
};

export default useCheckPermissions;
