export const ControlStatus = Object.freeze({
    NotStarted: { id: 0, value: 'NotAssessed', color: '#374957' },
    Started: { id: 1, value: 'InProgress', color: '#00A3FF' },
    Completed: { id: 2, value: 'Completed', color: '#20C9AC' }
});
export const CompletedStatus = Object.freeze({
    Mature: '#6d9eeb',
    Defined: '#93c47d',
    Developing: '#fcdc64',
    Initial: '#f4b46c',
    'Non-Existent': '#e06666',
    'Not Applicable': '#c1c1c1',
    'Not Assessed': '#CCC0DA'
});

export function findControlStatusById(id) {
    for (const status of Object.values(ControlStatus)) {
        if (status.id === id) {
            return status;
        }
    }
    return null;
}
