import { Button } from 'antd';
import './Button.less';

const ButtonType = {
    primary: 'primary',
    dashed: 'dashed',
    link: 'link',
    text: 'text',
    outlined: 'outlined',
    default: 'default'
};
const ButtonSize = {
    large: 'large',
    middle: 'middle',
    small: 'small',
    default: 'default'
};

const StickButton = ({ children, type, size, style, ...rest }) => {
    const antButtonType = ButtonType[type] || ButtonType.default;
    const antButtonSize = ButtonSize[size] || ButtonSize.default;

    return (
        <Button
            type={antButtonType}
            {...rest}
            style={style}
            size={antButtonSize}
            className={`stick-button stick-button-${antButtonType}`}>
            {children}
        </Button>
    );
};

export default StickButton;
