import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import { createReduxHistoryContext } from 'redux-first-history';
import { combineReducers } from 'redux';
import globalReducer from './globalReducer';
import userReducer from './userReducer';
import notificationReducer from './notificationReducer';
import assessmentReducer from './AssessmentReducers/maturityAssessmentReducers';
import internalReducer from './AssessmentReducers/internalAuditReducers';
import uploadevidenceReducer from './uploadEvidencesReducer';
import config from '@/config';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory()
});

const currentEnvironmentMode = config.mode;

export default function store(initialState = {}) {
    const middlewares = [routerMiddleware];

    if (currentEnvironmentMode === 'development') {
        const loggerMiddleware = createLogger();
        middlewares.push(loggerMiddleware);
    }

    const store = configureStore({
        reducer: combineReducers({
            global: globalReducer,
            user: userReducer,
            notifications: notificationReducer,
            assessment: assessmentReducer,
            router: routerReducer,
            evidence: uploadevidenceReducer,
            internal: internalReducer
        }),
        middleware: (getDefaultMiddleware) => [...middlewares, ...getDefaultMiddleware()],
        initialState,
        preloadedState: initialState,
        composeWithDevToolsDevelopmentOnly
    });

    const history = createReduxHistory(store);

    return { store, history };
}
