import { Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import config from '../../../config';

const { Title } = Typography;

const PageTitleComponent = ({ title }) => (
    <Row style={{ marginBottom: '1rem' }}>
        <Col flex='auto'>
            <Title style={{ marginBottom: '0' }}>{title}</Title>
        </Col>
    </Row>
);

PageTitleComponent.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

PageTitleComponent.defaults = {
    title: config.title
};

export default PageTitleComponent;
