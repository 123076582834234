import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';

const defaultComparer = (a, b) => {
    if (a.position !== undefined && b.position !== undefined) {
        return a.position - b.position;
    } else if (a.name !== undefined && b.name !== undefined) {
        return a.name.localeCompare(b.name);
    } else if (a.createdOn !== undefined && b.createdOn !== undefined) {
        return a.createdOn - b.createdOn;
    } else {
        return 0;
    }
};

const controlSetAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => defaultComparer(a, b)
});
const initialState = {
    currentEvidence: {
        id: undefined,
        name: undefined,
        identifier: undefined,
        description: undefined,
        evidenceTypeId: undefined,
        versionsId: undefined,
        evidenceCategoryName: undefined,
        evidenceCategoryId: undefined
    },
    controlSets: controlSetAdapter.getInitialState()
};
const evidenceSlice = createSlice({
    name: 'evidence',
    initialState,
    reducers: {
        setCurrentEvidence: (state, action) => {
            state.currentEvidence = {
                ...state.currentEvidence,
                ...action.payload
            };
        }
    },
    resetevidenceState: () => {
        return {
            ...initialState
        };
    }
});

export const { resetevidenceState, setCurrentEvidence } = evidenceSlice.actions;

const selectEvidenceState = (state) => state.evidence?.currentEvidence || initialState.currentEvidence;

const selectEvidenceId = createSelector([selectEvidenceState], (currentEvidence) => {
    return currentEvidence ? currentEvidence.evidenceTypeId : null;
});
const selectEvidenceCategoryId = createSelector([selectEvidenceState], (currentEvidence) => {
    return currentEvidence ? currentEvidence.evidenceCategoryId : null;
});

export { selectEvidenceId, selectEvidenceCategoryId };
export default evidenceSlice.reducer;
