import { createEntityAdapter } from '@reduxjs/toolkit';
import { defaultComparer } from '../helpers';

export const controlMaturityStatusAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => defaultComparer(a, b)
});

export const controlCategoryAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => defaultComparer(a, b)
});

export const controlSetAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => defaultComparer(a, b)
});

export const controlAdapter = createEntityAdapter({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => defaultComparer(a, b)
});

export const controlResponseAdapter = createEntityAdapter({
    selectId: (entity) => entity.controlId
});
