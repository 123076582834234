import { useEffect } from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useCheckPermissions from '@/hooks/useCheckPermissions';
import { Permission } from '@/components/enum/Permission';

const UnAuthorized = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { hasPermission } = useCheckPermissions(Permission.DashboardView);
    useEffect(() => {
        document.title = t('error:403:title');
    }, [t]);

    const navigateHome = () => {
        navigate(hasPermission ? '/' : '/assessments/internal');
    };

    return (
        <Result
            status='403'
            title={t('error:403:title')}
            subTitle={t('error:403:content')}
            extra={
                <Button
                    type='primary'
                    onClick={navigateHome}>
                    {t('navigate.home')}
                </Button>
            }
        />
    );
};

export default UnAuthorized;
