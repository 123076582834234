import { createSlice } from '@reduxjs/toolkit';
import { getItem } from '../utilities/storage';
import { DeviceType, getDevice } from '../utilities/device';

// const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
const systemTheme = 'light';
const userTheme = getItem('theme');
const device = getDevice() || DeviceType.DESKTOP;

const initialState = {
    theme: userTheme || systemTheme,
    navigating: false,
    loading: false,
    device: device,
    collapsed: !!(device == DeviceType.MOBILE || device === DeviceType.TABLET),
    currentPage: localStorage.getItem('currentPage') || ''
};

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setGlobalState(state, action) {
            Object.assign(state, action.payload);

            if (action.payload.theme) {
                const body = document.body;

                if (action.payload.theme === 'dark') {
                    if (!body.hasAttribute('theme-mode')) {
                        body.setAttribute('theme-mode', 'aaa');
                    }
                } else {
                    if (body.hasAttribute('theme-mode')) {
                        body.removeAttribute('theme-mode');
                    }
                }
            }
        }
    }
});

export const { setGlobalState } = globalSlice.actions;

export default globalSlice.reducer;
