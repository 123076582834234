import createCommonSlice from './Slice/createCommonSlice';
import * as baseSelectors from './Selectors/baseSelectors';
import * as derivedSelectors from './Selectors/derivedSelectors';
import { createDerivedSelectorsForSlice, createSelectorsForSlice } from './Selectors/selectorUtils';
import { commonInitialState } from './Slice/commonSlice';
import { controlResponseAdapter } from './Adapters';
import { mergeEvidence, updateIfNewer } from './helpers';
import { createSelector } from '@reduxjs/toolkit';

const currentPage = 'internal';

//additional reducers for maturity assessment
const internalReducer = {
    updateControlResponse: (state, action) => {
        const {
            controlId,
            completionStatusId,
            completionStatusName,
            controlMaturityStatusId,
            isNotApplicable,
            estimatedForAttainingMatureStatus,
            statementOfApplicabilityReasoning,
            notes,
            supportingEvidence,
            mappedEvidenceTypes,
            lastModifiedOn,
            keyFinding,
            latestControlMaturityStatusName,
            recommendations,
            controlEvaluation,
            controlEvaluationLastModifiedOn
        } = action.payload;
        const existingResponse = controlResponseAdapter
            .getSelectors((state) => state.controlResponses)
            .selectById(state, controlId);
        if (existingResponse) {
            // const mergedNotes = mergeNotes(existingResponse.notes, notes);
            const mergedNotes = notes;
            const mergedEvidence = mergeEvidence(existingResponse.supportingEvidence, supportingEvidence);
            controlResponseAdapter.updateOne(state.controlResponses, {
                id: controlId,
                changes: {
                    completionStatusId: updateIfNewer(
                        existingResponse.completionStatusId,
                        completionStatusId,
                        existingResponse.lastModifiedOn,
                        lastModifiedOn
                    ),
                    completionStatusName: updateIfNewer(
                        existingResponse.completionStatusName,
                        completionStatusName,
                        existingResponse.lastModifiedOn,
                        lastModifiedOn
                    ),
                    controlMaturityStatusId: updateIfNewer(
                        existingResponse.controlMaturityStatusId,
                        controlMaturityStatusId,
                        existingResponse.lastModifiedOn,
                        lastModifiedOn
                    ),
                    isNotApplicable,
                    estimatedForAttainingMatureStatus: updateIfNewer(
                        existingResponse.estimatedForAttainingMatureStatus,
                        estimatedForAttainingMatureStatus,
                        existingResponse.lastModifiedOn,
                        lastModifiedOn
                    ),
                    statementOfApplicabilityReasoning: updateIfNewer(
                        existingResponse.statementOfApplicabilityReasoning,
                        statementOfApplicabilityReasoning,
                        existingResponse.lastModifiedOn,
                        lastModifiedOn
                    ),
                    notes: mergedNotes,
                    supportingEvidence: mergedEvidence,
                    mappedEvidenceTypes,
                    keyFinding,
                    latestControlMaturityStatusName,
                    recommendations,
                    controlEvaluation,
                    controlEvaluationLastModifiedOn,
                    lastModifiedOn: Math.max(lastModifiedOn, existingResponse.lastModifiedOn || lastModifiedOn)
                }
            });
        } else {
            controlResponseAdapter.setOne(state.controlResponses, action.payload);
        }
    }
};

//addition states for internal assessment
const initialState = {
    currentAssessment: {
        ...commonInitialState.currentAssessment,
        latestControlMaturityStatusName: ''
    }
};

//creates slices and selectors for internal assessment reducers including the common reducers
const internalSlice = createCommonSlice(currentPage, initialState, internalReducer);
const internalBaseSelectors = createSelectorsForSlice(baseSelectors, currentPage);
const internalDerivedSelectors = createDerivedSelectorsForSlice(derivedSelectors, currentPage);

//export the reducers, selectors and actions
export const {
    setCurrentAssessment,
    setIsSavingChanges,
    setHasUnsavedChanges,
    setControlMaturityStatuses,
    setControlCategories,
    setControlSets,
    setControls,
    setActiveControl,
    setIsRefreshInterface,
    setControlResponses,
    updateControlResponse,
    updateControlResponseStatus,
    updateControlResponseEvidence,
    navigateToNextControl,
    navigateToPreviousControl,
    updateAssessmentProgressStatusId,
    resetAssessmentState,
    setMultipleCategories,
    setMultipleControlSets,
    setSelectedControls,
    setMultipleControls,
    deselectAllControls
} = internalSlice.actions;

export const {
    selectAllControlCategories,
    selectAllControlMaturityStatuses,
    selectAllControlSets,
    selectAllControls,
    selectControlById,
    selectControlCategoryById,
    selectControlMaturityStatusById,
    selectControlSetById,
    selectResponseByControlId,
    selectAllControlResponses,
    selectAssessmentState,
    selectSelectedMultiControls,
    selectActiveControlState
} = internalBaseSelectors;

export const selectLatestMaturityStatusName = createSelector(
    [selectAssessmentState],
    (currentAssessment) => currentAssessment?.latestControlMaturityStatusName || ''
);

export const {
    selectDefaultControlMaturityStatus,
    selectControlSetsByCategoryId,
    selectControlsByCategoryIdAndSetId,
    selectResponseControlStatusByControlId,
    selectCategoryDataForListView,
    selectControlMaturityStatusSummaryReport,
    selectRequirementTypeReport,
    selectCanMoveForward,
    selectCanMoveBackward,
    selectAssessmentId,
    selectFrameworkId,
    selectKeyFindings,
    selectAssessmentProgressStatusId,
    selectIsReadOnly,
    selectIsSavingChanges,
    selectHasUnsavedChanges,
    selectControlId,
    selectAllControlSetStatuses,
    selectAllControlCategoryStatuses,
    selectControlCategoryData,
    selectControlSetData,
    selectControlData,
    selectCompletedControlsCount
} = internalDerivedSelectors;

export default internalSlice.reducer;
